<template>
  <div></div>
</template>

<script>
import { memberGroupCheckout } from '@/api/group-buying';

export default {
  computed: {
    memberGroupBuyingOrderId() {
      return this.$route?.query?.memberGroupId;
    }
  },
  watch: {
    memberGroupBuyingOrderId: {
      immediate: true,
      handler(newVal) {
        if (newVal != undefined) {
          this.redirectToPayment();
        }
      }
    }
  },
  methods: {
    redirectToPayment() {
      memberGroupCheckout(this.memberGroupBuyingOrderId)
        .then(data => {
          if (data?.html) {
            document.write(data.html);
          }
        })
        .catch(() => {
          this.$router.push({ name: 'FollowedGroupList' });
        });
    }
  }
};
</script>
